<template>
	<v-card>
		<v-dialog v-model="dialog.status" persistent scrollable>
			<CardDialog :dialog="dialog" v-if="dialog.msg" />
			<v-card v-else max-width="1200">
				<v-card-title>Alterar permissões</v-card-title>
				<v-divider />
				<v-card-text v-if="permissoes.lista">
					<div v-for="(g, i) in grupoPermissoes" :key="i">
						<v-row>
							<v-col cols="12">
								<div
									v-if="permissoes.lista.filter(v => {return v.idpermissao.split('_')[0] == g.filtro }).length > 0"
									class="text-h6 black--text pt-4"
								>{{g.titulo}}</div>
							</v-col>
							<v-col
								cols="12"
								sm="6"
								md="4"
								lg="3"
								class="py-0"
								v-for="(u, i) in permissoes.lista.filter(v => {return v.idpermissao.split('_')[0] == g.filtro })"
								:key="i"
							>
								<v-chip label class="pt-1 my-1 pl-2" outlined color="primary">
									<v-checkbox v-model="permissoesSelecionadas" :value="u.idpermissao" :label="u.permissao" />
								</v-chip>
							</v-col>
						</v-row>
						<v-divider
							class="mt-5"
							v-if="permissoes.lista.filter(v => {return v.idpermissao.split('_')[0] == g.filtro }).length > 0"
						/>
					</div>
				</v-card-text>
				<v-card-text v-else>
					<v-skeleton-loader
						class="mx-auto d-block d-sm-none"
						width="300"
						type="list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
					></v-skeleton-loader>
					<v-skeleton-loader
						class="mx-auto d-none d-sm-block d-md-none"
						width="600"
						type="list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
					></v-skeleton-loader>
					<v-skeleton-loader
						class="mx-auto d-none d-md-block"
						width="1000"
						type="list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
					></v-skeleton-loader>
				</v-card-text>
				<v-card-actions class="pa-5">
					<v-spacer />
					<v-btn color="primary" elevation="0" class="px-5" @click="dialog = false">Voltar</v-btn>
					<v-btn color="primary" elevation="0" class="px-5" @click="atualizarPermissoes()">Salvar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-card-title :class="alterarPermissao ? 'mb-n1' : ''">
			Permissões
			<v-spacer />
			<v-btn
				v-if="idgrupo != 1 && alterarPermissao"
				color="primary"
				elevation="0"
				@click="listarPermissoes()"
			>
				<v-icon>mdi-pencil</v-icon>
				<span class="pl-3 d-none d-sm-block">Alterar permissões</span>
			</v-btn>
		</v-card-title>
		<v-divider />
		<v-progress-linear indeterminate absolute :active="carregando" />
		<v-card-text>
			<v-simple-table>
				<template v-slot:default>
					<thead>
						<tr>
							<th class="text-left">Categoria</th>
							<th class="text-left">Permissões</th>
						</tr>
					</thead>
					<tbody>
						<template v-if="dados.total > 0">
							<RowGroupPermission
								v-for="(u, i) in grupoPermissoes"
								:key="i"
								:titulo="u.titulo"
								:filtro="u.filtro"
								:dados="dados"
							/>
						</template>
						<template v-else>
							<tr>
								<td class="text-center" colspan="2">Nenhum registro encontrado.</td>
							</tr>
						</template>
					</tbody>
				</template>
			</v-simple-table>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import RowGroupPermission from "../Widgets/RowGroupPermission";
import CardDialog from "../Widgets/CardDialog";

export default {
	name: "UsuarioGrupoListaPermissao",
	components: { RowGroupPermission, CardDialog },
	props: ["idgrupo", "idusuario", "alterarPermissao"],
	data: () => ({
		dialog: { status: false },
		carregando: false,
		dados: {},
		permissoes: {},
		busca: {},
		permissoesSelecionadas: [],
		grupoPermissoes: [
			{ titulo: "Aluguel", filtro: "aluguel" },
			{ titulo: "Balanço", filtro: "balanco" },
			{ titulo: "Chamado", filtro: "chamado" },
			{ titulo: "Cliente", filtro: "cliente" },
			{ titulo: "Contrato", filtro: "contrato" },
			{ titulo: "Dashboard", filtro: "dashboard" },
			{ titulo: "Gente & Gestão", filtro: "departamento" },
			{ titulo: "Entrega", filtro: "entrega" },
			{ titulo: "Ferramenta", filtro: "ferramenta" },
			{ titulo: "Filial", filtro: "filial" },
			{ titulo: "Grupo", filtro: "grupo" },
			{ titulo: "Marketing", filtro: "marketing" },
			{ titulo: "Pedido", filtro: "pedido" },
			{ titulo: "Produto", filtro: "produto" },
			{ titulo: "Orçamento", filtro: "orcamento" },
			{ titulo: "Seguro", filtro: "seguro" },
			{ titulo: "TI", filtro: "ti" },
			{ titulo: "Usuário", filtro: "usuario" },
			{ titulo: "Venda", filtro: "venda" },
		],
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit"]),
	},
	methods: {
		listar() {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}usuario/listapermissoes`, {
					idgrupo: parseInt(this.idgrupo) || null,
					idusuario: parseInt(this.idusuario) || null,
				})
				.then((res) => {
					this.dados = res.data;
					this.permissoesSelecionadas = this.dados.lista.map((v) => {
						return v.idpermissao;
					});
					this.carregando = false;
				})
				.catch(() => {
					this.dados = { total: 0 };
					this.carregando = false;
				});
		},
		listarPermissoes() {
			this.dialog = { status: true };
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}permissao/listar`)
				.then((res) => {
					this.permissoes = res.data;
					this.carregando = false;
				})
				.catch(() => {
					this.permissoes = { total: 0 };
					this.carregando = false;
				});
		},
		atualizarPermissoes() {
			let permissoes = this.permissoesSelecionadas.map((v) => {
				return { idpermissao: v };
			});
			return axios
				.post(`${this.backendUrl}usuario/grupo/permissao/atualizar`, {
					idgrupo: parseInt(this.idgrupo),
					permissoes: permissoes,
				})
				.then((res) => {
					if (res) {
						this.dialog.title = "Permissões atualizadas";
						this.dialog.msg =
							"Permissões de grupo atualizadas com sucesso!";
						this.dialog.icon = "mdi-check-circle-outline";
						this.dialog.iconColor = "success";
						this.listar();
					} else {
						this.dialog.title = "Erro";
						this.dialog.msg =
							"Não foi possível alterar as permissões!";
						this.dialog.icon = "mdi-alert-circle-outline";
						this.dialog.iconColor = "red";
					}
				})
				.catch(() => {
					this.dialog.title = "Erro";
					this.dialog.msg = "Não foi possível alterar as permissões!";
					this.dialog.icon = "mdi-alert-circle-outline";
					this.dialog.iconColor = "red";
				});
		},
	},
	created() {
		this.listar(0);
	},
};
</script>

<style scoped>
</style>