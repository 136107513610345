<template>
    <tr>
        <td>{{titulo}}</td>
        <td class="text-left">
            <template
                v-for="(u, i) in dados.lista.filter(v => {return v.idpermissao.split('_')[0] == filtro})"
            >
                <v-chip
                    small
                    color="primary"
                    class="mr-1 my-1 font-weight-bold"
                    :key="u.id"
                >{{u.permissao}}</v-chip>
                <strong
                    :key="i"
                    v-if="dados.lista.filter(v => {return v.idpermissao.split('_')[0] == filtro}).length != i+1"
                >/</strong>
            </template>
        </td>
    </tr>
</template>

<script>
export default {
    name: "RowGroupPermission",
    props: ["titulo", "filtro", "dados"],
};
</script>