<template>
	<v-row>
		<v-col cols="12" md="4">
			<v-card class="mx-auto">
				<v-card-title>Informações</v-card-title>
				<v-divider />
				<v-progress-linear indeterminate absolute :active="carregando" />
				<v-card-text>
					<div class="my-3">
						<v-icon class="mr-2">mdi-identifier</v-icon>
						<span class="font-weight-bold">ID:</span>
						{{dados.idusuario}}
					</div>
					<div class="my-3">
						<v-icon class="mr-2">mdi-account-outline</v-icon>
						<span class="font-weight-bold">Nome:</span>
						{{dados.nome}}
					</div>
					<div class="my-3">
						<v-icon class="mr-2">mdi-account</v-icon>
						<span class="font-weight-bold">Usuário:</span>
						{{dados.usuario}}
					</div>
					<div v-if="dados.filial" class="my-3">
						<v-icon class="mr-2">mdi-office-building</v-icon>
						<span class="font-weight-bold">Filial:</span>
						{{dados.filial.filial}}
					</div>
					<div v-if="dados.grupo" class="my-3">
						<v-icon class="mr-2">mdi-account-multiple</v-icon>
						<span class="font-weight-bold">Grupo:</span>
						{{dados.grupo.grupo}}
					</div>
					<div class="my-3">
						<v-icon class="mr-2">mdi-account-group-outline</v-icon>
						<span class="font-weight-bold">Departamento:</span>
						{{dados.departamento}}
					</div>
					<div class="my-3">
						<v-icon class="mr-2">mdi-account-check</v-icon>
						<span class="font-weight-bold mr-3">Status:</span>
						<v-chip v-if="dados.status" small color="success" class="font-weight-bold">Ativo</v-chip>
						<v-chip v-else dark color="red" small class="font-weight-bold">Inativo</v-chip>
					</div>
					<div class="my-3">
						<v-icon class="mr-2">mdi-clock</v-icon>
						<span class="font-weight-bold">Último acesso:</span>
						{{dados.dtultimologin}}
					</div>
				</v-card-text>
				<v-card-actions class="pa-4">
					<v-spacer />
					<v-btn
						:disabled="carregando"
						color="primary"
						elevation="0"
						v-if="!dados.status"
						@click="ativaInativaUsuario()"
					>Ativar Usuário</v-btn>
					<v-btn
						:disabled="carregando"
						color="primary"
						elevation="0"
						v-else
						@click="ativaInativaUsuario()"
					>Inativar Usuário</v-btn>
				</v-card-actions>
			</v-card>
		</v-col>
		<v-col cols="12" md="8">
			<UsuarioGrupoListaPermissao
				v-if="dados.grupo"
				:idgrupo="dados.grupo.idgrupo"
				:idusuario="dados.idusuario"
				:alterarPermissao="false"
			/>
		</v-col>
	</v-row>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import UsuarioGrupoListaPermissao from "../Usuario/UsuarioGrupoListaPermissao";

export default {
	name: "UsuarioGet",
	components: { UsuarioGrupoListaPermissao },
	props: ["idusuario"],
	data: () => ({
		carregando: false,
		dados: {},
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit"]),
	},
	methods: {
		get() {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}usuario/get`, {
					idusuario: parseInt(this.idusuario),
				})
				.then((res) => {
					this.dados = res.data;
					this.carregando = false;
				})
				.catch(() => {
					this.carregando = false;
				});
		},
		ativaInativaUsuario() {
			return axios
				.post(`${this.backendUrl}usuario/status`, {
					idusuario: parseInt(this.idusuario),
					ativa: !this.dados.status,
				})
				.then(() => {
					this.get();
				});
		},
	},
	created() {
		this.get();
	},
};
</script>

<style scoped>
</style>